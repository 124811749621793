import { employeeinfo } from "../commonDynmicForms/employee_info";
import { FillableBy } from "./form_enums";
import { getSignatures } from "../commonDynmicForms/signature";
import { table_fields} from "../commonDynmicForms/table_fields"
import { evaluationCheckList } from "../commonDynmicForms/evaluation_checklist";
import { constants } from "../constants";

export default {
    fillable_by : FillableBy.Both,
    title:
        "Spectrum Quantum Competency Checklist for Normothermic Regional Perfusion (NRP)",
    sections: [
        // {...employeeinfo},
        {
            title: "General Knowledge & Training",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "<b>Instruction(s) for Use Availability</b><br/>1. Can locate and reference the manufacturer’s<b> Instructions for Use (IFU)</b>.<br/>2. Understands how to access digital/printed manuals and quick reference guides.",
                "<b>E-Learning Enrollment & Completion</b><br/>1. Completed manufacturer’s</b> online training and certification modules</b> related to device function.<br/>2. Passed any required <b>knowledge assessments or quizzes</b>.<br/>3. Completed training specific to <b>NRP protocols and procedures</b>."
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Equipment Familiarization & Setup",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "<b>Hardware Components</b><br/>1. Can identify and explain all key components of the<b> Spectrum Quantum Heart-Lung Machine for NRP use</b>.<br/>2. Understands <b>power supply requirements, battery backup function, and cable management</b>.<br/>3. Demonstrates proper assembly and <b>setup of centrifugal pump circuit,</b> for NRP.",
                "<b>Sterile Technologies</b><br/>1. Understands <b>the importance of maintaining a sterile field</b> during circuit setup and priming.<br/>2.Demonstrates correct handling of <b>sterile tubing, connectors, and oxygenators</b>.<br/>3. Verifies sterility and proper <b>priming of the NRP circuit</b> before use.",
                "<b>Gauge Bar/Diagnostics/Capture-Sync</b><br/>1. Correctly interprets <b>pressure, temperature, and flow readings</b> for NRP.<br/>2. Verifies <b>functionality of real-time diagnostics and alarm logging</b>.<br/>3.Synchronizes <b>data capture with organ procurement records (if applicable)</b>.",
                "<b>System Settings</b><br/>1. Configures <b>patient-specific settings </b>including <b>RPM, flow rates, and occlusion settings</b> for NRP.<br/>2. Adjusts system parameters based on <b>donor physiology and surgical team requirements</b>.<br/>3. Verifies settings before and during <b>NRP initiation.</b>",
                "<b>Pump Manager (Centrifugal Pump System)</b><br/>1. Operates <b>centrifugal pump system</b> effectively for NRP circulation.<br/>2. Adjusts <b>RPM and flow settings</b> to ensure optimal perfusion of <b>abdominal or thoracic organs</b>.<br/> 3. Monitors <b>circuit integrity, pressure gradients, and oxygenator performance</b>.",
                "<b> Pump Interventions (Bubble Detection, Level, Pressure Monitoring)</b><br/>1. Demonstrates competency in <b>air bubble detection and intervention</b>.<br/>2. Correctly responds to <b>low-level alarms and adjusts as needed</b>.<br/>3.Monitors <b>pre-membrane and post-membrane pressures</b> to identify potential circuit issues.<br/>4. Ensures <b>appropriate pressure monitoring</b> to maintain perfusion without damaging organs.s",
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Advanced Features & Safety Protocols",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "<b>Interlock Safeties and Alarm Management</b><br/>1. Understands <b>alarms related to NRP flow, pressure, and air detection</b>.<br/>2. Configures and verifies <b>alarm settings before NRP initiation</b>.<br/>3. Responds appropriately to <b>alarms, pressure drops, or flow disruptions.</b>",
                "<b>Smart Modes</b><br/>1. Engages and utilizes <b>Smart Modes for automated adjustments</b> in NRP perfusion.<br/>2. Explains how different <b>Smart Modes impact regional perfusion and pressure stabilization</b>.<br/>3. Adjusts settings based on <b>organ-specific requirements and surgical needs.</b>",
                "<b>VIPER and Toolbox Applications</b><br/>1. Accesses and navigates <b>VIPER (Variable Input Patient Electronic Record)</b>.<br/>2. Uses <b>Toolbox Applications</b> for troubleshooting and optimizing performance.<br/>3. Logs and retrieves <b>NRP-specific perfusion data</b> from the machine.",
                "<b>Device Connectivity</b><br/>1. Establishes <b>connectivity with external NRP monitoring systems</b>.<br/>2. Integrates with <b>Electronic Medical Records (EMR) or Organ Procurement Logs if applicable.</b><br/>3. Troubleshoots <b>common connectivity issues</b>.",
                "<b>Administrator Mode (Superusers Only)</b><br/>1. Accesses <b>advanced system settings and configurations</b>.<br/>2. Performs <b>software updates and calibrations as needed</b>.<br/>3. Assigns <b>user access levels and manages system security</b>.",
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Clinical Application & Performance",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "<b>Trainee Observation</b><br/>1. Shadowed a trained <b>NRP perfusionist or surgical team member</b> during machine setup and initiation.<br/>2. Asked <b>questions and demonstrated understanding of key functions</b>.",
                "<b>Clinical Case(s) Performed</b><br/>1. Successfully <b>operated the Spectrum Quantum during a live NRP case</b>.<br/>2. Adjusted parameters in response to <b>changes in donor hemodynamics</b>.<br/>3. Worked effectively as part of the <b>NRP surgical and organ procurement team</b>.",
                "<b>Emergency Procedures</b><br/>1. Responds effectively to <b>power failure and battery backup activation,/b>.<br/>2. Demonstrates proper protocol for <b>pump failure and emergency hand cranking (if applicable)</b>.<br/>3. Handles <b>air embolism detection and response</b>.<br/>4. Implements contingency plans for <b>oxygenator failure or clot formation</b>.<br/>5. Responds appropriately to <b>flow or pressure failure scenarios</b>.<br/>6. Ensures proper <b>circuit management and intervention in the event of sudden flow loss</b>.",
                "<b>NRP-Specific Considerations</b><br/>1. Understands <b>perfusion goals for abdominal (liver, kidneys, pancreas) vs. thoracic (heart, lungs) NRP</b>.<br/>2. Monitors <br>lactate clearance and metabolic stability</b> as indicators of organ viability.<br/>3. Ensures <b>appropriate heparinization and anticoagulation strategies</b> during perfusion.<br/>4. Works with <b>OPO coordinators to transition from NRP to organ procurement</b> efficiently."
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {...evaluationCheckList},
        {
            title: "Signatures",
            type: "signature",
            fields: getSignatures(FillableBy.Both),
        },
    ],
}